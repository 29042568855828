import buildQuery from 'odata-query'
import { type Observable } from 'rxjs'
import { type AjaxResponse } from 'rxjs/ajax'

import { type OrganizationModalReq } from '../../../entities/Organizations/OrganizationForm/OrganizationForm.store'
import { type AccountModel } from '../../models/Account/Account.model'
import {
    type IConclusionAddResponse,
    type IConclusionEditResponse,
    type IConclusionResponse
} from '../../models/Conclusion/Conclusion.model'
import { type INotifier } from '../../providers/notifications/notifications.context'
import { type AuthService } from '../../services'
import { type CommentEditResponse } from '../../ui/Measurements/models/MeasurementComments.model'
import { type ICalculateNorm, type ITrackingInfoDto } from '../../ui/Measurements/models/Measurements.model'
import { type IAddUserResponse, type IUserDto, type IUserResponse } from '../../ui/User'

export interface LoginRequest {
    email: string
    password: string
}

export interface ResetPasswordRequest {
    email: string
}

export interface RestorePasswordRequest {
    code: string
    password: string
}

export interface ChangePasswordRequest {
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

export interface OrganizationDto {
    id: number
    idExt: string
    email: string | null
    address: string | null
    created: string
    level: number
    license: string
    name: string
    parent: {
        address: string
        created: string
        email: string
        idExt: string
        license: string
        name: string
        phone: string
    }
    parentId: number | null
    phone: string | null
    children?: OrganizationDto[]
    status: string
}

export interface IMeasurementsTargetLevelDto {
    '@odata.context': string
    'targetLevel': string
}

export interface AddOrganizationDto {
    idExt: string | null
    email: string | null
    address: string | null
    name: string
    parentId: number | null
    phone: string | null
    status: string
}

export interface OrganizationsResponse {
    '@odata.count': number
    'value': OrganizationDto[]
}

export interface IntegrationTokenResponse {
    token: string
    id: number
    doctorId: string
}

export interface IntegrationTokenTempResponse {
    '@odata.context': string
    'token': string
}

export interface editOrganizationsResponse extends OrganizationDto {
    '@odata.context': string
}

export interface DiagnosisDto {
    id: number
    code: string
    description: string
}

export interface NotificationsDto {
    id: number
    created: string
    isRead: boolean
    message: string
    recipientId: number
    title: string
}

export interface EditDiagnosisDto {
    code: string | null
    description: string | null
}

export interface DiagnosisResponse {
    '@odata.count': number
    'value': DiagnosisDto[]
}

export interface NotificationsResponse {
    '@odata.count': number
    'value': NotificationsDto[]
}

export interface editDiagnosisResponse extends DiagnosisDto {
    '@odata.context': string
}

export interface UserAllMeasurementsDto {
    firstName: string
    middleName: string
    lastName: string
    username: string
    sex: string
    birthDate: string
    orgId: number
}

export interface IMeasurementDto {
    id: number
    created: string
    userId: number
    type: string
    source: string
    value: string
    parameters: ParametersDto[]
    needInspection: boolean
    commentsNumber: number
    norm: string
    trackingInfo: ITrackingInfoDto[]
    user: UserAllMeasurementsDto
    skipDef?: boolean
    skipDias?: boolean
    calculateNorm?: ICalculateNorm
}

export interface MeasurementsResponse {
    '@odata.count': number
    'value': IMeasurementDto[]
}

export interface IInspectionDto {
    id: number
    isActive: boolean
    doctorId: number
    patientId: number
    created: string
    conclusions: ConclusionDto[]
    doctor: DoctorDto
}

export interface ConclusionDto {
    conclusionId: number
    conclusionType: string
    datetime: string
}

export interface DoctorDto {
    firstName: string
    lastName: string
    middleName: string | null
    birthDate: string
    email: string
    phone: string
}

export interface InspectionsResponse {
    '@odata.count': number
    'value': IInspectionDto[]
}

export interface addInspectionResponse {
    '@odata.context': string
    'id': number
    'isActive': boolean
    'doctorId': number
    'patientId': number
    'created': string
    'conclusions': ConclusionDto[]
    'doctor': DoctorDto
}

export interface addInspectionCardResponse {
    '@odata.context': string
    'id': number
    'medicalInspectionId': number
    'doctorName': string
    'omsPoliceNumber': string
    'address': string
    'schoolNumber': string
    'schoolAddress': string
    'firstName': string
    'middleName': string
    'lastName': string
    'sex': string
    'birthDate': string
    'organizationName': string
    'medicalInspectionStartDate': string
    'height': number
    'weight': number
    'bmi': number
    'physicalDevelopment': string
    'healthBeforeExamination': string
    'dispensaryObservation': string
    'healthGroup': string
    'physEdMedicalGroup': string
    'consultationsAssigned': string
    'treatmentPrescribed': false
    'treatmentStage': string
    'rehabilitationAssigned': false
    'rehabilitationStage': string
    'boysSexFormula': string | null
    'girlsSexFormula': string | null
    'characteristicsMenstrualFunction': string | null
    'psychomotorSphere': string | null
    'intelligence': string | null
    'emotionalVegetativeSphere': string | null
    'cognitiveFunction': string
    'motorFunction': string
    'emotionalSocialFunction': string
    'prespeechSpeechDevelopment': string
    'chestCircum': number
    'shoulderCircum': number
    'abdominalCircum': number
    'thighCircum': number
    'calfCircum': number
    'headCircum': number
    'diagnosesSummary': Diagnoses[]
}

export interface getInspectionCardsResponse {
    '@odata.context': string
    'value': addInspectionCardResponse[]
}

export interface Diagnoses {
    conclusionType: string
    mainDiagnosis: string
    diseasesForm: string
    firstRevealed: string
}

export interface CommentDto {
    id: number
    created: string
    value: string
    user: {
        id: number
        orgId: number
    }
    measurementId: number
    updated: any
}

export interface CommentResponse {
    '@odata.context': string
    '@odata.count': number
    'value': CommentDto[]
}

export interface FilterRequests {
    orderBy?: string[]
    top?: number
    skip?: number
    filter?: any
    search?: any
}

export interface EditUserRequest {
    email?: string | null
    firstName?: string | null
    lastName?: string | null
    middleName?: string | null
    birthDate?: string | null
    sex?: string | null
    height?: number | null
    weight?: number | null
    phone?: string | null
    orgId?: number | null
    role?: string | null
    username?: string
}

export interface ParametersDto {
    type: string
    value: string
}

export interface UrgentInspectionResponse {
    id: number
    commentsNumber: number
    created: string
    userId: number
    type: string
    source: string
    value: string
    needInspection: boolean
    norm: number
    parameters: ParametersDto[]
    user: UserAllMeasurementsDto
}

export interface LimitsDto {
    min: number
    max: number
    measurementType: string
    userId?: number
    initiatorId?: number
}

export interface GetUserLimits {
    value: LimitsDto[]
}

export interface GetSubscribersDto {
    id: number
    created: string
    publisherId: number
    subscriberId: number
    eventType: string
}

export interface GetSubscribersResponse {
    value: GetSubscribersDto[]
}

export interface ResponseParametersResponse {
    value: ParametersDto[]
}

export interface RequestIntegrationLogin {
    doctorId: string
    organizationId: string
}

export interface IInspection {
    id: string
    externalId: string
    patientId: string
    doctorId: string
    organizationId: string
    organizationName: string
    deviceId: string
    deviceType: string
    status: string
    startDate: string
    endDate: string | null
}

interface IDeviceInspection {
    id: string
    type: number
}

export interface IDoctorInspection {
    id: string
    firstName: string
    lastName: string
    middleName: string
}

export interface IPatientInspection {
    id: string
    firstName: string | null
    lastName: string | null
    middleName: string | null
    birthDate: string | null
    diagnosis: string | null
    height: number | null
    phone: string | null
    sex: string | null
    weight: number | null
    anamnesis: string | null
}

export interface IOrganizationInspection {
    id: string
    email: string | null
    name: string | null
    phone: string | null
}

export interface IIndividualMeasurementLevels {
    maxValue: number
    minValue: number
    measurementType: string
    standardValue: number
}

export interface IInspectionDetail {
    id: string
    device: IDeviceInspection
    doctor: IDoctorInspection
    patient: IPatientInspection
    organization: IOrganizationInspection
    individualMeasurementLevels: IIndividualMeasurementLevels[]
    requiredMeasurementTimes: null
    startDate: string
    comment: string | null
    endDate: string
}

export interface IInspectionsOData {
    '@odata.context': string
    'value': IInspection[]
}

export interface IGynecologist {
    medicalInspectionId?: number | null
    datetime: string
    complaining: string
    anamnesis: string

    heredity: string
    sexualGrowth: string
    sexFormula: string
    puberty: string
    menstrualFunction: string
    integuments: string
    bodyHairGrowth: string
    genitalia: string
    clitoris: string
    gymen: string
    sexLife: string
    vulvaMucosa: string
    discharges: string
    abdomenPalpation: string
    performedManipulations: string

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}

export interface IConclusionDiagnosis {
    mainDiagnosis: string
    diseasesForm: string
    firstRevealed: number | null
    dispensaryRegistered: number | null
    concomitant: string
    complication: string
    dispensaryGroup: number | null
    appointments: string
    examinations: string
}
export interface IConclusionGynecologistOData {
    '@odata.count': number
    'value': IGynecologist[]
}

export interface INeurologist {
    medicalInspectionId: number
    datetime: string
    complaining: string
    anamnesis: string

    status: string
    consciousness: string
    meningealSymptoms: string
    smell: string
    sight: string
    oculomotorFunctions: string
    trigeminalNerveFunctions: string
    facialNerve: string
    hearing: string
    glossopharyngealAndVagusNerves: string
    eleventhCranialNerve: string
    hypoglossalNerve: string
    bulbarSyndrome: string
    pseudobulbarSyndrome: string
    motorSphere: string
    motorHyperactivity: string
    sensitiveSphere: string
    vegetativeSphere: string
    pelvicFunctions: string
    enuresis: string
    cognitiveFunction: string
    emotionalSphere: string
    performedManipulations: string

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}
export interface IConclusionNeurologistOData {
    '@odata.count': number
    'value': IGynecologist[]
}

export interface IOtorhinolaryngologist {
    medicalInspectionId: number
    datetime: string
    complaining: string
    anamnesis: string

    zev: string
    tonsils: string
    nasalBreathing: string
    nasalCavity: string
    nasalMucosa: string
    polyps: string
    nasals: string
    nasalSeptum: string
    nasopharynx: string
    adenoides: string
    otherEntities: string
    pharynx: string
    mucous: string
    lymphNodes: string
    voiceFunction: string
    throat: string
    ears: string
    outerEar: string
    earCanal: string
    eardrum: string
    hearing: string
    tuningForkExamination: string
    vestibularFunctions: string

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}
export interface IConclusionOtorhinolaryngologistOData {
    '@odata.count': number
    'value': IOtorhinolaryngologist[]
}

export interface IOphthalmologist {
    medicalInspectionId: number
    datetime: string
    complaining: string
    anamnesis: string

    visualAcuityWithoutCorrection: string
    visualAcuityWithCorrection: string
    orbitAndEyePosition: string
    squint: string
    eyelids: string
    eyeMucosa: string
    cornea: string
    itch: string
    pupil: string
    pupilReactionLight: string
    anteriorChamber: string
    deepEnvironments: string
    eyeground: string
    retinalVessels: string
    retina: string
    performedManipulations: string

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}
export interface IConclusionOphthalmologistOData {
    '@odata.count': number
    'value': IOphthalmologist[]
}

export interface IPediatrician {
    medicalInspectionId: number
    datetime: string
    complaining: string
    anamnesis: string

    weight: number
    height: number
    headCircumference: number | null
    physicalDevelopment: string
    cognitiveFunction: string
    motorFunction: string
    emotionalSocialFunction: string
    prespeechSpeechDevelopment: string
    psychomotorSphere: string
    intelligence: string
    emotionalVegetativeSphere: string
    boysSexFormula: string
    girlsSexFormula: string
    characteristicsMenstrualFunction: string
    healthBeforeExamination: string
    healthGroup: string
    physEdMedicalGroup: string
    healthAccordingExamination: string
    firstRevealed: number | null
    dispensaryObservation: string
    consultationsAssigned: boolean
    consultationsAssignedStage: string
    consultationsPerformed: boolean
    consultationsPerformedStage: string
    treatmentPrescribed: boolean
    treatmentStage: string
    rehabilitationAssigned: boolean
    rehabilitationStage: string

    doctorName: string
}
export interface IConclusionPediatricianOData {
    '@odata.count': number
    'value': IPediatrician[]
}

export interface IPsychiatrist {
    medicalInspectionId: number
    datetime: string
    complaining: string
    anamnesis: string

    examination: string

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}
export interface IConclusionPsychiatristOData {
    '@odata.count': number
    'value': IPsychiatrist[]
}

export interface IDentist {
    medicalInspectionId: number
    datetime: string
    complaining: string

    visualExamination: string
    dentalHealth: DentalHealth

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}
export interface DentalHealth {
    t1: string
    t2: string
    t3: string
    t4: string
    t5: string
    t6: string
    t7: string
    t8: string
    t9: string
    t10: string
    t11: string
    t12: string
    t13: string
    t14: string
    t15: string
    t16: string
    t17: string
    t18: string
    t19: string
    t20: string
    t21: string
    t22: string
    t23: string
    t24: string
    t25: string
    t26: string
    t27: string
    t28: string
    t29: string
    t30: string
    t31: string
    t32: string
}
export interface IConclusionDentistOData {
    '@odata.count': number
    'value': IDentist[]
}

export interface ITraumatologist {
    medicalInspectionId: number
    datetime: string
    complaining: string
    anamnesis: string

    generalCondition: string
    bodyPosition: string
    constitution: string
    nutritionalStatus: string
    symmetryDevelopment: string
    tone: string
    volumeActiveMovements: string
    volumePassiveMovements: string
    postureDisturbance: string
    rachiocampsis: string
    chestNeckDeformity: string
    limbPosition: string
    axlesUpperLimbs: string
    axlesLowerLimbs: string
    feet: string
    lowerLimbsDeformity: string
    volumeJointMovements: string

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}
export interface IConclusionTraumatologistOData {
    '@odata.count': number
    'value': ITraumatologist[]
}

export interface IUrologist {
    medicalInspectionId: number
    datetime: string
    complaining: string
    anamnesis: string

    stomach: string
    kidneys: string
    effleurageSymptom: string
    bladder: string
    genitalia: string
    discharges: string
    urination: string
    genitalsPainOrDiscomfort: string

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}

export interface IConclusionUrologistOData {
    '@odata.count': number
    'value': IUrologist[]
}

export interface ISurgeon {
    medicalInspectionId: number
    datetime: string
    complaining: string
    anamnesis: string

    integuments: string
    moisture: string
    rashes: string
    lymphNodes: string
    localization: string
    state: string
    upperLimbs: string
    lowerLimbs: string
    color: string
    intermittentLameness: string
    skinTemperature: string
    pulsation: string
    vascularSystem: string
    tongue: string
    stomach: string
    atPalpation: string
    mammaryGlands: string
    palpatorno: string
    muscleProtection: string
    liver: string
    liverEdge: string
    stool: string
    rectum: string
    hemorrhoidalNodes: string
    infiltration: string
    fingerExamination: string
    coccyxAreaPalpation: string
    manipulationsPerformed: string

    optionalData: string
    conclusion: string
    recommendations: string

    diagnosis: IConclusionDiagnosis
    doctorName: string
}
export interface IConclusionSurgeonOData {
    '@odata.count': number
    'value': ISurgeon[]
}

export interface IBloodTest {
    medicalInspectionId: number
    datetime: string

    generalBloodTest: string
    erythrocytes: number | null
    hemoglobin: number | null
    colorIndex: number | null
    hematocrit: number | null
    reticulocytes: number | null
    platelets: number | null
    leukocytes: number | null
    basophiles: number | null
    eosinophils: number | null
    neutrophilsMyelocytes: number | null
    neutrophilsYoung: number | null
    rodNucleatedNeutrophils: number | null
    segmentedNeutrophils: number | null
    lymphocytes: number | null
    monocytes: number | null
    shiftIndex: number | null
    anisocytosis: number | null
    poikilocytosis: number | null
    erythrocyteResistance: number | null
    foldingTime: number | null
    bloodSedimentationRate: number | null
    deviations: boolean

    doctorName: string
}
export interface IConclusionBloodTestOData {
    '@odata.count': number
    'value': IBloodTest[]
}

export interface IUrinalysis {
    medicalInspectionId: number
    datetime: string

    urinalysis: string
    quantity: number | null
    color: string
    transparency: string
    relativeDensity: number | null
    reaction: string
    protein: number | null
    glucose: number | null
    ketoneBodies: number | null
    bloodReaction: string
    bilirubin: number | null
    urobilinoids: number | null
    bileAcids: number | null
    flatEpithelium: number | null
    transientEpithelium: number | null
    renalEpithelium: number | null
    leukocytes: number | null
    erythrocytes: number | null
    gealinCylinders: number | null
    granularCylinders: number | null
    waxCylinders: number | null
    epithelialCells: number | null
    leukocyteCells: number | null
    pigmentCells: number | null
    slime: number | null
    salts: number | null
    bacteria: number | null
    deviations: boolean

    doctorName: string
}
export interface IConclusionUrinalysisOData {
    '@odata.count': number
    'value': IUrinalysis[]
}

export interface IAbdominalRenalUltrasound {
    medicalInspectionId: number
    datetime: string

    abdominalRenalUltrasound: string
    description: string
    deviations: boolean

    doctorName: string
}
export interface IConclusionAbdominalRenalUltrasoundOData {
    '@odata.count': number
    'value': IAbdominalRenalUltrasound[]
}

export interface IElectrocardiography {
    medicalInspectionId: number
    datetime: string

    electrocardiography: string
    pPeak: string
    rPeak: string
    tPeak: string
    pInterval: string
    pqInterval: string
    qsInterval: string
    stInterval: string
    qrsInterval: string
    rrInterval: string
    electricalAxisDeviation: string
    extrasystole: string
    deviations: boolean

    doctorName: string
}
export interface IConclusionElectrocardiographyOData {
    '@odata.count': number
    'value': IElectrocardiography[]
}

export abstract class Telemed<O = unknown> {
    abstract setUserContext(context: AccountModel | null): void

    public context: AccountModel | null | undefined
    public isIntegration: boolean = false

    abstract updateParams(authService: AuthService, notifications: INotifier): void

    protected abstract request<T>(
        method: string,
        url: string,
        headers: Record<string, string | null>,
        options?: O,
        requestBody?: any
    ): Observable<T>

    login(data: LoginRequest) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/Login`,
            headers,
            undefined,
            data
        )
    }

    integrationTempLogin() {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<IntegrationTokenTempResponse>('GET', '/SingleUsingToken', headers, undefined)
    }

    integrationLogin(token: string, data: RequestIntegrationLogin) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json', 'Key': token }
        return this.request<IntegrationTokenResponse>('POST', `/SingleUsingTokenLogin`, headers, undefined, data)
    }

    logout() {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>('POST', `${this.isIntegration ? '' : '/v4'}/Users/Logout`, headers, undefined)
    }

    sendRestoreCode(data: ResetPasswordRequest) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/SendRestoreCode`,
            headers,
            undefined,
            data
        )
    }

    sendRestorePassword(data: RestorePasswordRequest) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/RestorePassword`,
            headers,
            undefined,
            data
        )
    }

    sendChangePassword(data: ChangePasswordRequest) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/ChangePassword`,
            headers,
            undefined,
            data
        )
    }

    getOrganizations(data?: any): Observable<OrganizationsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<OrganizationsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Organizations${buildQuery({
                count,
                ...data
            })}`,
            headers
        )
    }

    getChildrenOrganizations(id: number, data?: any): Observable<OrganizationsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<OrganizationsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Organizations(${id})/GetChildren` + buildQuery({ count, ...data }),
            headers
        )
    }

    editOrganization(
        id: number,
        data: Omit<OrganizationModalReq, 'id'>,
        rqOptions?: O
    ): Observable<editOrganizationsResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editOrganizationsResponse>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Organizations(${id})`,
            headers,
            rqOptions,
            data
        )
    }

    addOrganization(
        data: Omit<OrganizationModalReq & { status: string }, 'id'>,
        rqOptions?: O
    ): Observable<editOrganizationsResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editOrganizationsResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Organizations`,
            headers,
            rqOptions,
            data
        )
    }

    getDiagnosis(data?: any): Observable<DiagnosisResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<DiagnosisResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Diagnoses${buildQuery({
                count,
                ...data
            })}`,
            headers
        )
    }

    getNotifications(id: number | undefined, data?: any): Observable<NotificationsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<NotificationsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Notifications` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    editNotifications(id: number | undefined): Observable<NotificationsDto[]> {
        const headers = { Accept: '*/*' }
        return this.request<NotificationsDto[]>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Notifications/MarkAllAsRead`,
            headers
        )
    }

    deleteNotifications(id: number | undefined): Observable<NotificationsDto[]> {
        const headers = { Accept: '*/*' }
        return this.request<NotificationsDto[]>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Notifications`,
            headers
        )
    }

    editDiagnosis(id: number, data?: EditDiagnosisDto, rqOptions?: O): Observable<editDiagnosisResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editDiagnosisResponse>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Diagnoses(${id})`,
            headers,
            rqOptions,
            data
        )
    }

    addDiagnosis(data: EditDiagnosisDto, rqOptions?: O): Observable<editDiagnosisResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editDiagnosisResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Diagnoses`,
            headers,
            rqOptions,
            data
        )
    }

    deleteDiagnosis(id: number, rqOptions?: O): Observable<editDiagnosisResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editDiagnosisResponse>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Diagnoses(${id})`,
            headers,
            rqOptions
        )
    }

    getAllMeasurements(data?: any): Observable<MeasurementsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<MeasurementsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Measurements` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    getInspections(id: number | undefined, data?: any): Observable<InspectionsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<InspectionsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/MedicalInspections` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    addInspection(id: string | undefined): Observable<addInspectionResponse> {
        const headers = { Accept: '*/*' }
        return this.request<addInspectionResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/MedicalInspections`,
            headers
        )
    }

    closeInspection(id: string | undefined, idInspection: number): Observable<InspectionsResponse> {
        const headers = { Accept: '*/*' }
        return this.request<InspectionsResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/MedicalInspections(${idInspection})/Close`,
            headers
        )
    }

    createInspectionCard(
        id: number | undefined,
        idInspection: number | undefined,
        data: any,
        rqOptions?: O
    ): Observable<addInspectionCardResponse> {
        const headers = { Accept: '*/*' }
        return this.request<addInspectionCardResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/MedicalInspections(${idInspection})/CreateCard`,
            headers,
            rqOptions,
            data
        )
    }

    getInspectionCards(id: number | undefined, rqOptions?: O): Observable<getInspectionCardsResponse> {
        const headers = { Accept: '*/*' }
        return this.request<getInspectionCardsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/MedicalInspections/GetCards`,
            headers,
            rqOptions
        )
    }

    addMeasurementsComment(id: number, value: string, rqOptions?: O): Observable<CommentEditResponse> {
        const headers = { Accept: '*/*' }
        return this.request<CommentEditResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments`,
            headers,
            rqOptions,
            { value }
        )
    }

    deleteMeasurementsComment(id: number, idComment: number, rqOptions?: O): Observable<void> {
        const headers = { Accept: '*/*' }
        return this.request<void>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments(${idComment})`,
            headers,
            rqOptions
        )
    }

    editMeasurementsComment(id: number, idComment: number, data?: any, rqOptions?: O): Observable<CommentEditResponse> {
        const headers = { Accept: '*/*' }
        return this.request<CommentEditResponse>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments(${idComment})`,
            headers,
            rqOptions,
            data
        )
    }

    getAllMeasurementsComments(id: number, data?: any): Observable<CommentResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        const orderBy = ['id desc']
        return this.request<CommentResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments` +
                buildQuery({
                    count,
                    orderBy,
                    ...data
                }),
            headers
        )
    }

    getStatistics(data?: any, rqOptions?: O): Observable<Blob> {
        const headers = { Accept: '*/*' }
        return this.request(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Reports/GetStatisticsReportShort(dateFrom=${data.dateFrom},dateTo=${
                data.dateTo
            })`,
            headers
        )
    }

    getStatisticsWithDecoding(data?: any): Observable<AjaxResponse<Blob>> {
        const headers = { Accept: '*/*' }
        return this.request(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Reports/GetStatisticsReportFull` +
                buildQuery({
                    ...data
                }),
            headers
        )
    }

    getUsers(data?: any): Observable<IUserResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IUserResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users${buildQuery({
                count,
                ...data
            })}`,
            headers
        )
    }

    editUser(id: number, data?: EditUserRequest, rqOptions?: O): Observable<IUserDto> {
        const headers = { Accept: '*/*' }
        return this.request<IUserDto>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})`,
            headers,
            rqOptions,
            data
        )
    }

    addUser(data?: EditUserRequest, rqOptions?: O): Observable<IAddUserResponse> {
        const headers = { Accept: '*/*' }
        return this.request<IAddUserResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/Register`,
            headers,
            rqOptions,
            data
        )
    }

    getUser(id: string, rqOptions?: O): Observable<IUserDto> {
        const headers = { Accept: '*/*' }
        return this.request<IUserDto>('GET', `${this.isIntegration ? '' : '/v4'}/Users(${id})`, headers, rqOptions)
    }

    setUrgentInspection(measurementId: number): Observable<UrgentInspectionResponse> {
        const headers = { Accept: '*/*' }
        return this.request<UrgentInspectionResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${measurementId})/SetUrgentInspection`,
            headers
        )
    }

    unsetUrgentInspection(measurementId: number): Observable<UrgentInspectionResponse> {
        const headers = { Accept: '*/*' }
        return this.request<UrgentInspectionResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${measurementId})/UnsetUrgentInspection`,
            headers
        )
    }

    getLimits(rqOptions?: O): Observable<GetUserLimits> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits>('GET', `${this.isIntegration ? '' : '/v4'}/Limits`, headers, rqOptions)
    }

    getEcg(id: string, stab: boolean, g35: boolean, g50: boolean, g75: boolean, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'GET',
            `${
                this.isIntegration ? '' : '/v4'
            }/Measurements(${id})/GetEcg(comp=${stab},hp1=true,bs50=${g50},lp35=${g35},lp75=${g75})`,
            headers,
            rqOptions
        )
    }

    getUserLimits(id: number, rqOptions?: O): Observable<GetUserLimits | LimitsDto[]> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits | LimitsDto[]>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Limits`,
            headers,
            rqOptions
        )
    }

    getUserInspections(data: any, rqOptions?: O): Observable<IInspectionsOData> {
        const headers = { Accept: '*/*' }
        return this.request<IInspectionsOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Inspections${buildQuery({
                ...data
            })}`,
            headers,
            rqOptions
        )
    }

    getUserInspection(id: string, rqOptions?: O): Observable<IInspectionDetail> {
        const headers = { Accept: '*/*' }
        return this.request<IInspectionDetail>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Inspections('${id}')`,
            headers,
            rqOptions
        )
    }

    addCommentInspection(id: string, data: { comment: string }, rqOptions?: O): Observable<IInspectionDetail> {
        const headers = { Accept: '*/*' }
        return this.request<IInspectionDetail>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Inspections('${id}')/AddComment`,
            headers,
            rqOptions,
            data
        )
    }

    getMeasurementsTargetLevel(
        patientId: number,
        type: string,
        rqOptions?: O
    ): Observable<IMeasurementsTargetLevelDto> {
        const headers = { Accept: '*/*' }
        return this.request<IMeasurementsTargetLevelDto>(
            'GET',
            `/Measurements/GetTargetLevel(patientId=${patientId},type='${type}')`,
            headers,
            rqOptions
        )
    }

    setUserLimits(id: number, data: LimitsDto[], rqOptions?: O): Observable<GetUserLimits> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Limits`,
            headers,
            rqOptions,
            data
        )
    }

    setRefLimits(data: LimitsDto[], rqOptions?: O): Observable<GetUserLimits> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Limits`,
            headers,
            rqOptions,
            data
        )
    }

    userVisit(id: string, rqOptions?: O): Observable<GetUserLimits> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Visit`,
            headers,
            rqOptions
        )
    }

    getSubscribers(subscriberId: number, publisherId: number, rqOptions?: O): Observable<GetSubscribersResponse> {
        const headers = { Accept: '*/*' }
        return this.request<GetSubscribersResponse>(
            'GET',
            `${
                this.isIntegration ? '' : '/v4'
            }/Users(${subscriberId})/Subscriptions/GetByPublisher(publisherId=${publisherId})`,
            headers,
            rqOptions
        )
    }

    setSubscribers(
        subscriberId: number,
        publisherId: number,
        data: { eventType: string }[],
        rqOptions?: O
    ): Observable<GetSubscribersResponse> {
        const headers = { Accept: '*/*' }
        return this.request<GetSubscribersResponse>(
            'POST',
            `${
                this.isIntegration ? '' : '/v4'
            }/Users(${subscriberId})/Subscriptions/Subscribe(publisherId=${publisherId})`,
            headers,
            rqOptions,
            data
        )
    }

    setParameters(userId: number, data: ParametersDto[], rqOptions?: O): Observable<ResponseParametersResponse> {
        const headers = { Accept: '*/*' }
        return this.request<ResponseParametersResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Parameters`,
            headers,
            rqOptions,
            data
        )
    }

    unSubscribers(
        subscriberId: number,
        publisherId: number,
        data: { eventType: string }[],
        rqOptions?: O
    ): Observable<GetSubscribersResponse> {
        const headers = { Accept: '*/*' }
        return this.request<GetSubscribersResponse>(
            'POST',
            `${
                this.isIntegration ? '' : '/v4'
            }/Users(${subscriberId})/Subscriptions/Unsubscribe(publisherId=${publisherId})`,
            headers,
            rqOptions,
            data
        )
    }

    setUserDiagnosis(userId: number, data: { code: string }[], rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Diagnoses`,
            headers,
            rqOptions,
            data
        )
    }

    unSetUserDiagnosis(userId: number, data: { code: string }[], rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Diagnoses`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionGynecologist(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionGynecologistOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Gynecologist` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionGynecologist(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Gynecologist`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionGynecologist(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Gynecologist`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionNeurologist(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionNeurologistOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Neurologist` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionNeurologist(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Neurologist`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionNeurologist(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Neurologist`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionOtorhinolaryngologist(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionOtorhinolaryngologistOData>(
            'GET',
            `${
                this.isIntegration ? '' : '/v4'
            }/Users(${userId})/Conclusions(${idConclusion})/Medmon.Otorhinolaryngologist` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionOtorhinolaryngologist(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Otorhinolaryngologist`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionOtorhinolaryngologist(
        userId: number,
        idConclusion: number,
        data: any,
        rqOptions?: O
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${
                this.isIntegration ? '' : '/v4'
            }/Users(${userId})/Conclusions(${idConclusion})/Medmon.Otorhinolaryngologist`,
            headers,
            rqOptions,
            data
        )
    }

    setConclusionPediatrician(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Pediatrician`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionPediatrician(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Pediatrician`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionPediatrician(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionPediatricianOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Pediatrician` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionOphthalmologist(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Ophthalmologist`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionOphthalmologist(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Ophthalmologist`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionOphthalmologist(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionOphthalmologistOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Ophthalmologist` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionPsychiatrist(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Psychiatrist`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionPsychiatrist(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Psychiatrist`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionPsychiatrist(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionPsychiatristOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Psychiatrist` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionDentist(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Stomatologist`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionDentist(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Stomatologist`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionDentist(userId: number | undefined, idConclusion: number | undefined, data?: any): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionDentistOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Stomatologist` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionTraumatologist(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Traumatologist`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionTraumatologist(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Traumatologist`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionTraumatologist(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionTraumatologistOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Traumatologist` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionUrologist(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Urologist`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionUrologist(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Urologist`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionUrologist(userId: number | undefined, idConclusion: number | undefined, data?: any): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionUrologistOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Urologist` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionSurgeon(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions/Medmon.Surgeon`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionSurgeon(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Surgeon`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionSurgeon(userId: number | undefined, idConclusion: number | undefined, data?: any): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionSurgeonOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Conclusions(${idConclusion})/Medmon.Surgeon` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionBloodTest(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes/Medmon.BloodTest`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionBloodTest(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes(${idConclusion})/Medmon.BloodTest`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionBloodTest(userId: number | undefined, idConclusion: number | undefined, data?: any): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionBloodTestOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes(${idConclusion})/Medmon.BloodTest` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionUrinalysis(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes/Medmon.Urinalysis`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionUrinalysis(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes(${idConclusion})/Medmon.Urinalysis`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionUrinalysis(userId: number | undefined, idConclusion: number | undefined, data?: any): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionUrinalysisOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes(${idConclusion})/Medmon.Urinalysis` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionAbdominalRenalUltrasound(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes/Medmon.Ultrasound`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionAbdominalRenalUltrasound(
        userId: number,
        idConclusion: number,
        data: any,
        rqOptions?: O
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes(${idConclusion})/Medmon.Ultrasound`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionAbdominalRenalUltrasound(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionAbdominalRenalUltrasoundOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes(${idConclusion})/Medmon.Ultrasound` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    setConclusionElectrocardiography(userId: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes/Medmon.Electrocardiography`,
            headers,
            rqOptions,
            data
        )
    }

    editConclusionElectrocardiography(userId: number, idConclusion: number, data: any, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes(${idConclusion})/Medmon.Electrocardiography`,
            headers,
            rqOptions,
            data
        )
    }

    getConclusionElectrocardiography(
        userId: number | undefined,
        idConclusion: number | undefined,
        data?: any
    ): Observable<any> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IConclusionElectrocardiographyOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Analyzes(${idConclusion})/Medmon.Electrocardiography` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    getConclusions(id: number, data?: any): Observable<IConclusionResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        const orderBy = ['id desc']
        return this.request<IConclusionResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/EcgConclusions` +
                buildQuery({
                    count,
                    orderBy,
                    ...data
                }),
            headers
        )
    }

    addMeasurementsConclusion(id: number, data: any, rqOptions?: O): Observable<IConclusionAddResponse> {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<IConclusionAddResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/EcgConclusions`,
            headers,
            rqOptions,
            data
        )
    }

    editMeasurementsConclusion(
        id: number,
        idConclusion: number,
        data?: any,
        rqOptions?: O
    ): Observable<IConclusionEditResponse> {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<IConclusionEditResponse>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/EcgConclusions(${idConclusion})`,
            headers,
            rqOptions,
            data
        )
    }

    getMeasurements(data?: any): Observable<MeasurementsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<MeasurementsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Measurements` +
                buildQuery({
                    count,
                    ...data
                }),
            headers,
            data
        )
    }

    addMeasurementComment(id: number, data: { value: string }, rqOptions?: O): Observable<CommentEditResponse> {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<CommentEditResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments`,
            headers,
            rqOptions,
            data
        )
    }

    deleteMeasurementComment(id: number, idComment: number, rqOptions?: O): Observable<void> {
        const headers = { Accept: '*/*' }
        return this.request<void>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments(${idComment})`,
            headers,
            rqOptions
        )
    }

    editMeasurementComment(
        id: number,
        idComment: number,
        data: { value: string },
        rqOptions?: O
    ): Observable<CommentEditResponse> {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<CommentEditResponse>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments(${idComment})`,
            headers,
            rqOptions,
            data
        )
    }

    getMeasurementComments(id: number, data?: any): Observable<CommentResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        const orderBy = ['id desc']
        return this.request<CommentResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments` +
                buildQuery({
                    count,
                    orderBy,
                    ...data
                }),
            headers,
            undefined,
            {
                count,
                orderBy,
                id,
                ...data
            }
        )
    }
}
