import {
    Breathalyzer,
    Ekg,
    FitnessBraclet,
    Fonedoscop,
    Glucometr,
    Pulseocsimetr,
    Spirometr,
    Termometr,
    Tonometr,
    UrineAnalisis,
    Weights
} from '../../assets'
import FonedoscopSrc from '../../assets/images/fonedoscop.svg'
import GlucometrSrc from '../../assets/images/glucometr.svg'
import SpirometrSrc from '../../assets/images/spirometr.svg'
import UrineAnalizisSrc from '../../assets/images/urine-analisis.svg'
import WeightsSrc from '../../assets/images/weights.svg'
import { type IInstrumentation } from '../../ui/Measurements/models/Measurements.model'

export const INSTRUMENTATION: IInstrumentation[] = [
    {
        code: 'manual',
        label: 'Вручную',
        typeMeasurements: ['insulin']
    },
    {
        code: 'smartwatch',
        label: 'Фитнес-браслет',
        typeMeasurements: ['steps', 'pulse', 'sleep'],
        icon: <FitnessBraclet />
    },
    {
        code: 'thermometer',
        label: 'Термометр',
        typeMeasurements: ['temperature'],
        icon: <Termometr />
    },
    {
        code: 'tonometer',
        label: 'Тонометр',
        typeMeasurements: ['blood_pressure', 'pulse'],
        icon: <Tonometr />
    },
    {
        code: 'glucosemeter',
        label: 'Глюкометр',
        typeMeasurements: ['glucose'],
        icon: <Glucometr />,
        iconSrc: GlucometrSrc
    },
    {
        code: 'pulseoxymeter',
        label: 'Пульсоксиметр',
        typeMeasurements: ['pulse'],
        icon: <Pulseocsimetr />
    },
    {
        code: 'scales',
        label: 'Весы',
        typeMeasurements: ['weight'],
        icon: <Weights />,
        iconSrc: WeightsSrc
    },
    {
        code: 'stethoscope',
        label: 'Цифровой фонендоскоп',
        typeMeasurements: ['auscult'],
        icon: <Fonedoscop />,
        iconSrc: FonedoscopSrc
    },
    {
        code: 'lung-tester',
        label: 'Спирометр',
        typeMeasurements: ['spirometry'],
        icon: <Spirometr />,
        iconSrc: SpirometrSrc
    },
    {
        code: 'urine-analyzer',
        label: 'Анализ мочи',
        typeMeasurements: ['urine'],
        icon: <UrineAnalisis />,
        iconSrc: UrineAnalizisSrc
    },
    {
        code: 'electrocardiograph',
        label: 'ЭКГ',
        typeMeasurements: ['ecg', 'ecg_new'],
        icon: <Ekg />
    },
    {
        code: 'breathalyzer',
        label: 'Алкотестер',
        typeMeasurements: ['bac'],
        icon: <Breathalyzer />
    }
]
